import * as imageSDK from '@wix/image-client-api'
import {CSSProperties} from 'react'

enum IMAGE_POSITION {
  CENTER_CENTER = 5,
}

export const SIDE_BY_SIDE_HEADER_IMAGE_SIZES = {
  FULL_WIDTH: 563.5,
  MINIMIZED: 490,
}

export const getImageStyle = ({
  image,
  container: {width, height},
  opacity = 100,
  position = IMAGE_POSITION.CENTER_CENTER,
  fitImage,
  superUpscaled = false,
}: GetImageStyleArgs) => {
  const isCropped = image.height && height ? image.width / image.height !== width / height : false
  const src = imageSDK.getScaleToFillImageURL(image.id, image.width, image.height, width, height, {
    quality: 90,
    focalPoint: isCropped ? resolveFocalPoint(position) : null,
    upscaleMethod: superUpscaled ? 'super' : 'auto',
  })

  const results: {
    src: string
    styles: CSSProperties
  } = {
    src,
    styles: {
      left: 0,
      opacity: opacity / 100,
      objectPosition: resolveImagePosition(position),
      objectFit: fitImage ? 'contain' : 'cover',
      width: 'calc(100% + 1px)',
      height: 'calc(100% + 1px)',
    },
  }

  return results
}

export const resolveFocalPoint = (position: IMAGE_POSITION) => {
  const focalPoint = {x: 50, y: 50}

  if (position % 3 === 0) {
    focalPoint.x = 100
  } else if ((position - 1) % 3 === 0) {
    focalPoint.x = 0
  }

  if (position < 4) {
    focalPoint.y = 0
  } else if (position >= 7) {
    focalPoint.y = 100
  }
  return focalPoint
}

const resolveImagePosition = (position: IMAGE_POSITION) => {
  let horizontal = '50%'
  let vertical = ''

  if (position % 3 === 0) {
    horizontal = '100%'
  } else if ((position - 1) % 3 === 0) {
    horizontal = '0%'
  }

  if (position < 4) {
    vertical = '0%'
  } else if (position < 7) {
    vertical = '50%'
  } else {
    vertical = '100%'
  }

  return `${horizontal} ${vertical}`
}

export interface Container {
  width: number
  height: number
}

interface GetImageStyleArgs {
  image: wix.common.Image
  container: Container
  bound?: boolean
  fitImage?: boolean
  opacity?: number
  position?: IMAGE_POSITION
  superUpscaled?: boolean
}
